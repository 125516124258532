<template>
  <div class="cont">
    <el-row type="flex" class="row-bg" justify="center">
      <el-col :span="7">
        <img class="logo" src="../assets/sys-logo.png" />
        <p>信息管理系统</p>
        <el-form :model="loginForm" :rules="rules" ref="loginForm">
          <el-form-item prop="username">
            <el-input v-model="loginForm.username" placeholder="请输入用户名"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input v-model="loginForm.password" type="password" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item class="code" prop="code">
            <el-input v-model="loginForm.code" maxlength="5"  placeholder="请输入验证码"></el-input>
            <el-image :src="captchaImg" class="captchaImg" @click="getCaptcha"></el-image>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('loginForm')">登录</el-button>
            <!-- <el-button @click="resetForm('loginForm')">重置</el-button> -->
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="8"></el-col>
    </el-row>
  </div>
</template>

<script>
  import qs from 'qs'
  import setNav from '../util/setNav.js'

  export default {
    name: "Login",
    data() {
      return {
        loginForm: {
          username: '',
          password: '',
          code: '',
          token: ''
        },
        rules: {
          username: [{
            required: true,
            message: '请输入用户名',
            trigger: 'blur'
          }],
          password: [{
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          }],
          code: [{
              required: true,
              message: '请输入验证码',
              trigger: 'blur'
            },
            {
              min: 5,
              max: 5,
              message: '长度为 5 个字符',
              trigger: 'blur'
            }
          ],
        },
        captchaImg: null
      };
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$axios.post('/login?' + qs.stringify(this.loginForm)).then(res => {
              const jwt = res.headers['authorization']
              this.$store.commit('SET_TOKEN', jwt)
              setNav()
              this.$router.push("/index")
            })
          } else {
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      getCaptcha() {
        this.$axios.get('/captcha').then(res => {
          this.loginForm.token = res.data.data.token
          this.captchaImg = res.data.data.captchaImg
          this.loginForm.code = ''
        })
      }
    },
    created() {
      this.getCaptcha()
    }
  }
</script>

<style scoped>
  .cont {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: white url(../assets/sys-bg.png) no-repeat center center/ 1920px 1080px;
  }
  .row-bg {
    position: relative;
    width: 100%;
    height: 100%;
    background: transparent url(../assets/sys-login-bg.png) no-repeat center center/ 90% auto;
  }

  .el-row {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .el-form {
    margin-top: 30px;
    padding: 0 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .logo {
    width: 240px;
  }
  p {
    padding-top: 4px;
    font-size: 26px;
    margin: 0;
    color: #646464;
    font-weight: bold;
  }
  .el-button {
    margin-top: 20px;
    width: 100%;
    padding: 12px 0;
    font-size: 18px;
    font-weight: bold;
  }
  .el-divider {
    height: 200px;
  }
  .captchaImg {
    float: left;
    margin-left: 8px;
    border-radius: 4px;
  }
  .el-input {
    font-size: 16px;
  }
  .el-row >>> .el-input__inner {
    border: none;
    border-bottom: 1px solid #999;
    border-radius: 0;
    background: transparent;
  }
  .code {
    position: relative;
  }
  .code .el-image {
    position: absolute;
    right: 0;
    top: 0;
    width: 110px;
  }
  @media screen and (max-width:1360px) {
    .row-bg {
      background: transparent url(../assets/sys-login-bg.png) no-repeat center center/ 100% auto;
    }
  }
</style>
